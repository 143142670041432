<template>
  <div>
    <Banner :data="dataBanner" />
    <FirstPenguin />
    <StudyDevelop />
    <Workplace />
    <Develop />
    <div class="welcomeProject">
      <Welcome :data="welcome" />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
const Banner = () => import('../components/Renew/Components/Banner.vue');
const FirstPenguin = () =>
  import('../components/Renew/People/FirstPenguin.vue');
const StudyDevelop = () =>
  import('../components/Renew/People/StudyDevelop.vue');
const Workplace = () => import('../components/Renew/People/Workplace.vue');
const Develop = () => import('../components/Renew/People/Develop.vue');
const Welcome = () => import('../components/Renew/Components/Welcome.vue');
const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'People',
  data() {
    return {
      header: this.$t('header'),
      welcome: this.$t('people').welcome,
      id: null,
      dataBanner: {
        title: this.$t('people').banner.title,
        sub: this.$t('people').banner.sub
      },
      seo: this.$t('seo').why_us
    };
  },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        {
          name: 'description',
          content: this.seo.description
        },
        {
          property: 'og:title',
          content: this.seo.title
        },
        {
          property: 'og:description',
          content: this.seo.description
        }
      ]
    };
  },
  props: {
    data: Array
  },

  created() {
    this.id = this.$route.query.id || null;
  },
  watch: {
    lang() {
      this.header = this.$t('header');
      this.welcome = this.$t('people').welcome;
      this.seo = this.$t('seo').why_us;
      this.dataBanner = {
        title: this.$t('people').banner.title,
        images: '/images/people/bg-banner-whyus.png',
        sub: this.$t('people').banner.sub
      };
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  components: {
    Banner,
    FirstPenguin,
    StudyDevelop,
    Workplace,
    Develop,
    Welcome,
    Footer
  }
};
</script>

<style lang="scss" scoped>
.welcomeProject {
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 50px;
  }
}
</style>
